<template>
  <section>
    <div v-if="product != undefined">
      <ProductCard />
    </div>
    <div v-if="product == undefined" class="compareBox">
      <header
        v-if="
          allCompareProducts == undefined ||
          allCompareProducts[keys.index] == undefined
        "
      >
        <v-icon> mdi-plus </v-icon>
        Add Car
      </header>
      <header
        v-if="allCompareProducts && allCompareProducts[keys.index] != undefined"
      >
        <img
          v-if="allCompareProducts[keys.index].medias"
          :src="allCompareProducts[keys.index].medias[0].mediaLink"
          alt=""
          class="compareImage"
        />
        <h2>{{ allCompareProducts[keys.index].productName }}</h2>
        <h4>{{ allCompareProducts[keys.index].category }}</h4>

        <v-btn
          class="primaryBtn mt-4"
          @click="deleteCompareProduct(allCompareProducts[keys.index]._id)"
          >Remove</v-btn
        >
      </header>
      <div
        v-if="
          !keys.limit && keys.index == allCompareProducts.length
        "
      >
        <v-autocomplete
          outlined
          placeholder="Select Category"
          v-model="category"
          :items="categories"
          item-text="value"
          item-value="value"
          @change="selectCategory"
          :readonly="model != undefined"
        ></v-autocomplete>
        <v-autocomplete
          outlined
          placeholder="Select Brand"
          v-model="brand"
          :items="brands"
          item-text="value"
          item-value="value"
          :readonly="model != undefined"
          @change="selectBrand"
        ></v-autocomplete>
        <v-autocomplete
          outlined
          placeholder="Select Model"
          v-model="model"
          :items="models"
          item-text="productName"
          item-value="_id"
          @change="selectProduct"
          :readonly="model != undefined"
        ></v-autocomplete>
      </div>
    </div>
  </section>
</template>

<script>
import ProductCard from "@/components/ProductCard";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CompareBox",
  props: ["keys"],
  data: () => ({
    brand: undefined,
    model: undefined,
    year: undefined,
    variant: undefined,
    categories: [],
    brands: [],
    models: [],
    years: [],
    variants: [],
    product: undefined,
    category: undefined,
    filters: undefined,
  }),
  components: {
    ProductCard,
  },
  methods: {
    ...mapActions([
      "getFilters",
      "getFrotendProducts",
      "getSingleFrotendProduct",
      "addCompareProducts",
      "deleteCompareProduct",
    ]),
    variantSelect() {
      this.product = {};
    },
    async selectCategory() {
      await this.getFilters({ category: this.category });
      this.filters = this.allFilters.results;
      this.brands = this.filters.brand.items;
    },
    async selectBrand() {
      await this.getFrotendProducts({
        category: this.category,
        brand: this.brand,
      });
      this.models = this.allProducts.results;
    },
    async selectProduct() {
      await this.getSingleFrotendProduct(this.model);
      this.addCompareProducts(this.allProduct.results);
    },
  },
  computed: {
    ...mapGetters([
      "allFilters",
      "allProducts",
      "allCompareProducts",
      "allProduct",
    ]),
  },
  async created() {
    let filter = {};
    await this.getFilters(filter);
    this.filterloading = false;
    this.filters = this.allFilters.results;
    this.categories = this.filters.category.items;
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.compareBox {
  border: 1px solid #ededed;
  border-radius: 5px;
  padding: 10px 20px 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  .compareImage {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin-top: -30px;
  }
  header {
    text-align: center;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-flow: column;
    width: 100%;

    justify-content: center;
    .v-icon {
      width: 80px;
      height: 80px;
      font-size: 60px;
      border: 5px dotted #a6a6a6;
      border-radius: 15px;
      padding: 5px;
      margin-bottom: 10px;
    }
  }
}
</style>