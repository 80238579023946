<template>
  <section>
    <section class="navBack">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" class="topNav">
            <ul class="navList">
              <li>Home</li>
              <li><v-icon> mdi-chevron-right </v-icon> Compare</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-container class="container-custom py-15">
      <v-row>
        <v-col
          md="3"
          cols="12"
          v-for="(data, index) in compares"
          class="compareBox"
          :key="index + 'car'"
          @click="compareAdd(index)"
        >
          <CompareBox :keys="data" v-if="data.show" />
        </v-col>
      </v-row>
      <v-row v-if="allCompareProducts && allCompareProducts.length > 0">
        <v-col md="12">
          <h2>Technical Specifications:</h2>
        </v-col>
        <v-col md="12" cols="12">
          <template>
            <v-simple-table class="tablebox">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Category</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].category }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].category }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].category }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].category }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Brand</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].brand }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].brand }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].brand }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].brand }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Model</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].productName }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].productName }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].productName }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].productName }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Year</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].year }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].year }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].year }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].year }}
                    </td>
                  </tr>

                  <tr>
                    <th class="text-left">Range (km)</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].range }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].range }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].range }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].range }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Battery Capacity</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].batteryCapacity }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].batteryCapacity }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].batteryCapacity }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].batteryCapacity }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Battery Type</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].batteryType }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].batteryType }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].batteryType }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].batteryType }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Accleration (0-100kmph)</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].acceleration }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].acceleration }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].acceleration }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].acceleration }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Trasmission Type</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].transmissionType }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].transmissionType }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].transmissionType }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].transmissionType }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Color</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      <strong
                        ><span
                          v-for="(data, index) in allCompareProducts[0].color"
                          :key="index + 'color'"
                          ><div
          
                            class="colorBox"
                            v-if="data != ''"
                          >
                            {{ data }}
                          </div>
                        </span></strong
                      >
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      <strong class="boxColor"
                        ><span
                          v-for="(data, index) in allCompareProducts[1].color"
                          :key="index + 'color'"
                          ><div
           
                            class="colorBox"
                            v-if="data != ''"
                          >
                            {{ data }}
                          </div>
                        </span></strong
                      >
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      <strong
                        ><span
                          v-for="(data, index) in allCompareProducts[2].color"
                          :key="index + 'color'"
                          ><div
           
                            class="colorBox"
                            v-if="data != ''"
                          >
                            {{ data }}
                          </div>
                        </span></strong
                      >
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      <strong
                        ><span
                          v-for="(data, index) in allCompareProducts[3].color"
                          :key="index + 'color'"
                          ><div
           
                            class="colorBox"
                            v-if="data != ''"
                          >
                            {{ data }}
                          </div>
                        </span></strong
                      >
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Seating Capacity</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].seatingCapacity }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].seatingCapacity }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].seatingCapacity }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].seatingCapacity }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Max Power (bhp@rpm)</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].power }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].power }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].power }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].power }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Max Torque (nm@rpm)</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].torque }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].torque }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].torque }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].torque }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Boot Space (nm@rpm)</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].bootSpace }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].bootSpace }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].bootSpace }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].bootSpace }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Body Type</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].bodyType }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].bodyType }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].bodyType }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].bodyType }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Ground Clearance</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].groundClearance }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].groundClearance }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].groundClearance }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].groundClearance }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Charging Time</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].charginTime }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].charginTime }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].charginTime }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].charginTime }}
                    </td>
                  </tr>
                  <tr>
                    <th class="text-left">Service Cost</th>
                    <td v-if="allCompareProducts[0] != undefined">
                      {{ allCompareProducts[0].serviceCost }}
                    </td>
                    <td v-if="allCompareProducts[1] != undefined">
                      {{ allCompareProducts[1].serviceCost }}
                    </td>
                    <td v-if="allCompareProducts[2] != undefined">
                      {{ allCompareProducts[2].serviceCost }}
                    </td>
                    <td v-if="allCompareProducts[3] != undefined">
                      {{ allCompareProducts[3].serviceCost }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <!-- <td>{{ item.name }}</td> -->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import CompareBox from "@/components/CompareBox";
import { mapGetters } from "vuex";
export default {
  name: "ComparePage",
  components: { CompareBox },
  data: () => ({
    compares: [
      {
        show: true,
        limit: false,
        index: 0,
      },
      {
        show: true,
        limit: false,
        index: 1,
      },
      {
        show: true,
        limit: true,
        index: 2,
      },
      {
        show: false,
        limit: true,
        index: 3,
      },
    ],
  }),
  computed: {
    ...mapGetters(["allCompareProducts"]),
  },
  methods: {
    compareAdd(index) {
      if (index + 1 <= this.compares.length) {
        if (index + 1 < this.compares.length) {
          this.compares[index + 1].show = true;
        }
        this.compares[index].limit = false;
      }
    },
  },
  created() {
    setTimeout(() => {
      this.allCompareProducts && this.allCompareProducts.length > 3
        ? (this.compares[3].show = true)
        : (this.compares[3].show = false);
    }, 10);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
.viewMore {
  .v-btn {
    text-transform: capitalize;
    min-height: 60px !important;
    width: 200px;
  }
}
.compareBox {
  cursor: pointer;
}
.boxColor {
  display: flex;
  flex-wrap: wrap;
  width: 200px;
}
.colorBox {
  padding: 2px 5px;
  border-radius: 5px;
  margin-right: 5px;
  margin-block: 4px;
  text-transform: uppercase;
  font-size: 12px;
}

</style>